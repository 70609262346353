(ns io.klei.lms.frontend.entity.registration
  (:require
    [pyramid.core :as p]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.utils :as utils]))

(def school-options
  [{:value "agca"
    :label "Angeli Gilbert Christian Academy"}
   {:value "hla"
    :label "Happy Learners Academy"}
   {:value "jrss"
    :label "Jesus the Risen Savior School"}
   {:value "vecc"
    :label "Victoria Elijah Christian College"}
   {:value "saint-paul"
    :label "Saint Paul University"}
   {:value "srk"
    :label "San Roque Kids Academy"}])

(defn section-name [reg]
  (get-in reg [:registration/section :section/name]))

(defn academic-year-name [reg]
  (or (get-in reg [:registration/section :section/academic-year :academic-year/name])
    (get-in reg [:registration/academic-year :academic-year/name])))

(defn registration-radio-text [reg]
  (str (academic-year-name reg)
       " / "
       (section-name reg)))

(def registration-pull-pattern
  [:registration/id
   :registration/date
   :registration/state
   :registration/academic-year-id
   :registration/level
   :registration/school-code
   {:registration/student [:student/id
                           :student/student-number
                           :auth/user-id
                           :person/first-name
                           :person/middle-name
                           :person/last-name]}
   {:registration/section [:section/id
                           :section/name]}])

(rf/reg-sub
  :entity.registration.sub/list
  (fn [db]
    (->> (for [id (keys (:registration/id db))]
           (-> (p/pull db [{[:registration/id id] registration-pull-pattern}])
               (get [:registration/id id])))
         (sort-by :registration/date #(compare %2 %1)))))

(rf/reg-sub
  :entity.registration.sub/one-by-id
  (fn [db [_ reg-id]]
    (when reg-id
      (utils/pull db [:registration/id reg-id] registration-pull-pattern))))
